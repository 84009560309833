import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/vercel/path0/.next/static/chunks/nextra-page-map-.mjs'

/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "404: Page Not Found";
const frontMatter = {};
import {NotFoundPage} from "nextra-theme-docs";
export function useTOC(props) {
  return [];
}
function MDXLayout(props) {
  const _components = {
    h1: "h1",
    ..._provideComponents(),
    ...props.components
  };
  return <><_components.h1>{"404: Page Not Found"}</_components.h1>{"\n"}<NotFoundPage /></>;
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/404',
  {"filePath":"pages/404.mdx","timestamp":1739550935000,pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)